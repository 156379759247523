import { useAppMessage, useDailyEvent } from '@daily-co/daily-react';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useRTVIClientReadyEmitter = () => {
  const { query } = useRouter();

  const sendAppMessage = useAppMessage();

  useDailyEvent(
    'participant-joined',
    useCallback(() => {
      if (!query.rtvi) return;
      sendAppMessage(
        {
          label: 'rtvi-ai',
          type: 'client-ready',
          data: {},
          id: 'rtvi-ai-client-ready',
        },
        '*'
      );
    }, [query.rtvi, sendAppMessage])
  );
};
